import * as React from "react";
import Layout from "../components/layout";
import Banner from "../components/services/banner";
import ServicesList from "../components/home/our-services/services-list";
import {ASSETS} from "../components/common/images";
import WhatWeDo from "../components/services/what-we-do";
import MobileAppDevelopment from "../components/services/mobile-app-development";
import IosDevelopment from "../components/services/ios-development";
import AndroidAppDevelopment from "../components/services/android-development";

const testPage = () => {
  return (
<h1> hello </h1>
  )
}
export default testPage;
